import "./../styles/global.css";
import h from "../styles/home.module.css"
import { Navbar } from "../components/Navbar"
import { HeaderImage, RImage } from "../components/Images"
import { Footer } from "../components/Footer"

export default function Home() {
    return(
        <>
            <Navbar/>
            <div className={ h.header }>
                <HeaderImage route="home/hero" alt="Mujer en la playa"/>
            </div>

            {/* Next adventure */}
            <div className={ h.adventure }>
                <img className={ h.adventure_bg2 } src="./images/home/adventure_bg2.png" srcSet="./images/home/adventure_bg2.webp" alt="Background"/>
                <img className={ h.adventure_bg } src="./images/home/adventure.png" srcSet="./images/home/adventure.webp" alt="Background"/>
                <div className={ h.adventure_images }>
                    <RImage route="home/adventure1" alt="Caminando en la playa"/>
                    <RImage route="home/adventure2" alt="Mujer surfeando"/>
                    <RImage route="home/adventure3" alt="Mujer con tortuga"/>
                    <div className={ h.adventure_images_des }>YOUR NEXT ADVENTURE AWAITS. WHAT ARE YOU WAITING FOR?</div>
                </div>
            </div>

            {/* RIDE THE WAVE */}
            <div className={ h.ride }>
                <HeaderImage route="home/ride" alt="Mujer surfeando"/>
            </div>

            {/* FOTOS */}
            <div className={ h.fotos }>
                <RImage route="home/fotos_bg" alt="Palmera"/>
                <div className={ h.fotos_content }>
                    <div className={ h.fotos_con }>
                        <div className={ h.fotos_con_text }>FIND YOUR ADVENTURE</div>
                        <RImage route="home/find_adventure" alt="Hombre pescando"/>
                    </div>
                    <div className={ h.fotos_con }>
                        <div className={ h.fotos_con_text }>DARE TO BE DEL MAR</div>
                        <RImage route="home/dare_del_mar" alt="Mujer sentada en palmera"/>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}