import p from "../styles/products.module.css"
import { RImage } from "./Images"

export function Merchandise({ img, subtitle, title, des, price }) {
    return(
        <div className={ p.merchandise_el }>
            <RImage route={ img } alt="Foto producto"/>
            <div className={ p.merchandise_el_subtitle }>{ subtitle }</div>
            <div className={ p.merchandise_el_title }>{ title }</div>
            <div className={ p.merchandise_el_des }>{ des }</div>
            <div className={ p.merchandise_el_price }>${ price } USD</div>
        </div>
    )
}

export function Cerveza({ img, nombre, precio}) {
    return(
        <div className={ p.producto }>
            <RImage route={ img } alt="Foto producto"/>
            <div className={ p.producto_name }>{ nombre }</div>
            <div className={ p.producto_des }>
                <div className={ p.producto_des_precio }>${ precio } USD</div>
                <a href="#" className={ p.producto_des_link }>SHOP</a>
            </div>
        </div>
    )
}