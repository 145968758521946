import { Link } from "react-router-dom"
import { RImage } from "./Images"

export function Footer() {
    return(
        <div className="footer">
            <RImage route="footer_bg" alt="Mujer caminando en la playa" classes="footer_bg"/>
            <div className="footer_shadow"></div>
            <div className="footer_content">
                <div className="footer_content">
                    <div className="footer_logo">
                        <RImage route="logo_white"/>
                    </div>
                    <div className="footer_story footer_el">
                        <div className="footer_story_title">KNOW OUR STORY</div>
                        <div className="footer_story_des">Brewing is our life, beer is our water so don’t waste time drinking other things.</div>
                    </div>
                    <div className="footer_contact footer_el">
                        {/*<a className="footer_contact_el" href="tel:+9999999999">+999 999 9999</a> */}
                        <a className="footer_contact_el" href="mailto:brewdelmar@gmail.com">brewdelmar@gmail.com</a>
                        <div className="footer_contact_el">La Paz, Baja California / Mazatlán, Sinaloa México.</div>
                    </div>
                    <div className="footer_menu footer_el">
                        <Link className="footer_menu_el" to="/">HOME</Link>
                        <Link className="footer_menu_el" to="/store">OUR PRODUCTS</Link>
                        <Link className="footer_menu_el" to="/store">SHOP NOW</Link>
                        <a className="footer_menu_el" href="#">FOLLOW US</a>
                    </div>
                </div>
                <div className="copyright">Copright by Del Mar. All rights reserved.</div>
            </div>
        </div>
    )
}