import "./../styles/global.css";
import p from "../styles/products.module.css"
import { NavbarBlack } from "../components/Navbar"
import { HeaderImage, RImage } from "../components/Images"
import { Footer } from "../components/Footer"
import { Cerveza, Merchandise } from "../components/Productos";

export default function Store() {
    return(
        <>
            <NavbarBlack/>
            <div className={ p.header }>
                <HeaderImage route="productos/hero" alt="Mujer en la playa"/>
            </div>

            {/* CERVEZAS */}
            <div className={ p.cervezas }>
                <div className={ p.cervezas_content }>
                    <div className={ p.cervezas_images }>
                        <RImage route="productos/beer1" alt="Cerveza"/>
                        <RImage route="productos/beer2" alt="Cerveza"/>
                        <RImage route="productos/beer3" alt="Cerveza"/>
                    </div>
                    <div className={ p.cervezas_t1 }>THERE ARE CERTAIN BEERS YOU HAVE TO DRINK</div>
                    <div className={ p.cervezas_t2 }>DARE TO BE DEL MAR</div>
                </div>
            </div>

            {/* OUR STORY */}
            <div className={ p.story }>
                <RImage route="productos/story_bg" alt="Fondo"/>
                <div className={ p.story_des }>
                    <div className={ p.story_des_content }>
                        <div className={ p.story_des_t1 }>know our story</div>
                        <div className={ p.story_des_t2 }>THERE IS A STORY BEHIND EVERY FLAVOR</div>
                        <div className={ p.story_des_sep }></div>
                        <div className={ p.story_des_t3 }>We're a movement of life. With flavors as dynamic as ocean waves, join us in ride of the boundless joy and coastal spirit that defines our corner of the world.</div>
                    </div>
                    <RImage route="productos/cervezas" alt="Cervezas"/>
                </div>
                <div className={ p.story_fotos }>
                    <RImage route="productos/story1"/>
                    <RImage route="productos/story2"/>
                    <RImage route="productos/story3"/>
                </div>
            </div>

            {/* SHOP */}
            <div className={ p.shop }>
                <div className={ p.shop_t1 }>SHOP</div>
                <div className={ p.shop_t2 }>Brewing is our life, beer is our water so don’t waste time drinking other things.</div>
                <div className={ p.shop_content }>
                    <Cerveza img="productos/buzz" nombre="Buzz Bait" precio="4.20"/>
                    <Cerveza img="productos/sandy" nombre="Sandy Vibes" precio="4.20"/>
                    <Cerveza img="productos/sunset" nombre="Sunset" precio="4.20"/>
                </div>
            </div>

            {/* MERCHANDISE */}
            <div className={ p.merchandise }>
                <div className={ p.merchandise_t1 }>MERCHANDISE</div>
                <div className={ p.merchandise_t2 }>We have a total irreverence for anything connected with society except that which makes the roads safer and the beer stronger.</div>
                <div className={ p.merchandise_content }>
                    <Merchandise img="productos/tote_bag" subtitle="APPAREL" title="TOTE BAG DEL MAR" price="22" des="Elevate your Baja style with our hipster-approved tote bags. Carry the coastal vibes wherever you go – because cool isn't just a look, it's a lifestyle."/>
                    <Merchandise img="productos/bucket_hat" subtitle="APPAREL" title="BUCKET HAT DEL MAR" price="22" des="Dive into laid-back Baja vibes with our trendy bucket hats. Embrace the sun-soaked coolness while making a style statement that's as bold as the peninsula itself."/>
                    <Merchandise img="productos/playera" subtitle="APPAREL" title="T SHIRT DEL MAR" price="22" des="Wrap yourself in Baja's cool essence with our hipster-chic t-shirts. Effortlessly stylish, these tees are more than fabric – they're a statement of the sun-soaked, laid-back lifestyle we live and love."/>
                </div>
            </div>

            {/* FOLLOW US */}
            <div className={ p.follow }>
                <RImage route="productos/follow1" alt="Cervezas en la playa"/>
                <RImage route="productos/follow2" alt="Cervezas en la playa"/>
                <div className={ p.follow_des }>
                    <div className={ p.follow_des_t1 }>We work hard. We deliver.</div>
                    <div className={ p.follow_des_t2 }>FOLLOW US</div>
                    <div className={ p.follow_des_social }>
                        <a href="https://www.facebook.com/delmar.brew" target="_blank"></a>
                        <a href="#" target="_blank"></a>
                        <a href="https://www.instagram.com/delmar.brew" target="_blank"></a>
                        <RImage route="productos/social" alt="Social media"/>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}